@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Kalam";
  src: url("../public/assets/fonts/Kalam/Kalam-Regular.ttf");
}

.loaderReconnection {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: loaderRotation 1s linear infinite;
  }

  @keyframes loaderRotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cigogne-position {
  bottom: -18px;
}

.cigogne-position:hover {
  bottom: -1.5px;
}

.checkProjectCigogne {
  right: -57px;
  bottom: -5px;
}

/* .checkProjectCigogne:hover{
  right: 3px;
  bottom: 32px;
} */

.button_config {
  width: 42px;
  height: 42px;
  border: 1px solid transparent;
  border-radius: 50%;
}

.button_config:hover {
  border: 2px solid #FFFFFF;
}


.toggle_config {
  width: 92px;
  height: 42px;
  border: 0px solid transparent;
  /* border-top-left-radius: 35%;
  border-bottom-left-radius: 35%; */
  /* border-end-end-radius: 35%; */
}

.card_recap_visi {
  top: max(18.0%, 113px);
  right: max(2.0%, 26px);
}

@keyframes slidein {
  from {
    translate: 0%;
  }

  to {
    translate: 250%;
  }
}

.card_recap_stored {
  top: max(26.8%, 169px);
  right: 0px;
  /* think abouto how to modifi to have a godd alternative values in dnyamic */
  /* animation: slidein 1s forwards; */
}

.card_recap__hidden {
  top: max(26.8%, 169px);
  right: -300px;
}
.card_recap_visible {
  top: max(26.8%, 169px);
  right: -151px;
  /* think abouto how to modifi to have a godd alternative values in dnyamic */
  /* animation: slidein 1s forwards; */
}


.rw-popup {
  position: fixed;
  z-index: 2000;;
}
/* avatar page styles */

.yellow_cigogne_postit {
  width: 173px;
  height: 217px;
  transform: rotate(10deg);
  top: 14px;
  left: -10px;
} 

.blue_cigogne_postit {
  width: 173.744px;
  height: 211.598px;
  transform: rotate(-4.408deg);
  bottom: max(105px, 16.64%);
  left: -34px;
}

.green_cigogne_postit {

  width: 175.606px;
  height: 213.157px;
  transform: rotate(10.807deg);
  top: max(165px, 26.15%);
  right: -45.5px;
}

.red_cigogne_postit {
  width: 172.929px;
  height: 215.706px;
  transform: rotate(-5.223deg);
  bottom: max(32.45px, 16.64%);
  right: -28px;
}

.player_postit {
  width: 220.47px;
  height: 231.823px;
  transform: rotate(-5.223deg);
  bottom: max(280px, 44.35%);
  left: 0px;
}

.dropdown_customized.rw-widget-input{
  background-color: #384983 ;
  color: white;
}

/* .dropdown_customized.rw-widget-picker{
 
} */

.dropdown_customized:focus.rw-widget-picker:focus {
  border-color: white;
  box-shadow: none;
}

.radio_customized{
  background-color: #384983 ;
  color: white;
}

.dropdown_content_projection.rw-widget-input {
  border: none;
  background-color: #384983;
  padding: 8px 10px;
  height: 40px;
  color: #FFFFFF;
}

.dropdown_effects_customized{
  background-color: #384983 ;
  color: white;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 14px;
  font-family: ROBOTO, sans-serif;
  overflow: auto;
}

*.rw-popup{
  position: absolute;
  z-index: 2000;
  /* overflow: auto; */
}

.dropdown_effects_customized.rw-popup{
  position: absolute;
  /* overflow: auto; */
}

.dropdown_effects_customized.rw-widget-input{
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #384983 ;
  color: white;
}
.dropdown_effects_customized:focus.rw-widget-picker:focus {
  border-color: white;
  box-shadow: none;
  font-size: 14px;
  font-family: ROBOTO, sans-serif;
}

.dropdown_effects_customized.rw-list-option{
  font-size: 14px;
  font-family: ROBOTO, sans-serif;
  padding: 0px;
}

.dropdown_effects_customized.rw-list-option.rw-state-selected{
  font-size: 14px;
  font-family: ROBOTO, sans-serif;
  padding: 0px;
}

.dropdown_effects_customized.rw-dropdown-list-input{
  font-size: 14px;
  font-family: ROBOTO, sans-serif;
  padding: 0px 0px;
}

.dropdown_effects_customized.rw-list
{
  font-size: 14px;
  font-family: ROBOTO, sans-serif;
  padding: 0px 0px;
}

.charge_style_width{
  width: calc((100% - 20px) / 3);
}
.card_team_admin-style-width{
  width: calc((100% - 10px) / 2);
}

/* Pour Firefox */
.firefox {
  scrollbar-width: thin;
  scrollbar-color: #FFFFFF transparent;
}

.firefox .scrollbar-black { 
  scrollbar-color: #000000 transparent;
}

/* Pour Chrome, Safari, et Opéra */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #FFFFFF; 
  border-radius: 2px; 
}

.scrollbar-black::-webkit-scrollbar-thumb {
  background-color: #000000;
}

/* pour les styles gérant le dynamique window*/


.postItWaitingRoom{
  width: min(22.66vw, 36.77vh);
  height: min(22.66vw, 36.77vh);
  /* padding-top: min(min(11.89vh,7.32vw ), 150px);
  padding-bottom: min(min(7.92vh, 4.88vw), 100px);
  padding-right: min(min(2.93vw, 4.75vh), 60) ;
  padding-left:  min(min(2.93vw, 4.75vh), 60) ; */
  container-name: postit;
  container-type: size;
}

.noteBookWaitingRoom{
  width: min(73.58vw, calc(84.24vh*1.3688));
  height: min(84.24vh, calc(73.58vw/1.3688));
  container-name: notebook;
  container-type: size;
}

.dilemeLogo{
  width: min(41.02vw, calc(26.31vh*2.53));
  height: min(26.31vh, calc(41.02vw/2.53));
}

.cigogneWaitingRoom{
  width: min(29.30vw, calc(44.37vh*1.07));
  height: min(44.37vh, calc(29.30vw/1.07));
}

.config-button{
  width: min(3.91vw, 6.34vh);
  height: min(6.34vh, 3.91vw);
  container-name: config-button;
  container-type: size;
}

.toogle_view{
  width: min(8.79vw, calc(6.34vh*2.25));
  height: min(6.34vh, calc(8.79vw/2.25));
}

.webcamPlayer{
  width: min( 24.71vw, calc(32.96vh*1.22));
  height: min(32.96vh, calc(24.71vw/1.22));
  container-name: webcam;
  container-type: size;
}

/* @container notebook (min-width: 10px){
  div{
    font-size: 10px;
  }
} */

.containerNotebook{
  container-name: notebook;
  container-type: size;
}

.noteBookWaitingRoomPosition{
  bottom: max(max(-38vw, -61vh), calc(-0.61*1100px));
}

.noteBookIncomeSlot{
  width: min(13.48vw, calc(22.82vh*0.9583));

  height: min(22.82vh, calc(13.48vw/0.9583));
}

.noteBookFixedCharges{
  width: min( 13.67vw, calc(10vh*2.22));
  height: min(10vh, calc( 13.67vw/2.22));
  container-name: noteBookFixedCharges;
  container-type: size;
}

.noteBookPositionClose{
  left: max( -72.34%, -113vh );
  bottom: max(-18.17%, -28.5vh);
}

.noteBookPositionOpen{
  left: min( -8.66%, 113vh );
  bottom: min(-3.83%, 28.5vh);
}

.noteBookBailAndPleasure{
  width: min( 1.56vw, 2.54vh);

  height: min(2.54vh, 1.56vw);
}

.myAvatarInTeam{
  width: min(11.72vw, 19.02vh);
  height: min(19.02vh, 11.72vw);
} 
.myCigogneInTeam{
  width: min(19.53vw, 31.70vh);
  height: min(31.70vh, 19.53vw);
}

.teamHolder{
  width: min( 24.41vw, calc(31.70vh*1.25));
  height: min(31.70vh, calc(24.41vw/1.25));
  container-name: teamHolder;
  container-type: size;

}
.teamateAvatarInTeam{
  width: min( 7.81vw, 12.68vh);
  height: min(12.68vh, 7.81vw);
}

.moneyAndPleasure{
  width: min(23.44vw, calc(7.92vh*4.8));
  height: min(7.92vh, calc(23.44vw/4.8));
  container-name: moneyAndPleasure;
  container-type: size;
}
.buttonNoteBook{
  width: min(4.93vw, calc(10.54vh* 0.76));
  height: min(10.54vh, calc(4.93vw/0.76));
}

.cardNote{
  width: min(17.58vw, calc(42.79vh*0.66));
  height: min(42.79vh, calc(17.58vw/0.66));
  container-name: cardNote;
  container-type: size;
  
}

.whiteSun{
  width: min( 39.06vw, calc( 63.39vh*1));
  height: min(63.39vh, calc(39.06vw/1));
  container-name: whiteSun ;
  container-type: size;
}
.cardToDrawDeck{
  width: min(33.20vw, calc(44.06vh*1.22));
  height: min(44.06vh, calc(33.20vw/1.22));
  container-name: cardToDrawDeck;
  container-type: size;
}

.drawCardBoard{
  width: min(30.66vw, calc(21.24vh*2.34));
  height: min(21.24vh, calc(30.66vw/2.34));
  container-name: drawCardBoard;
  container-type: size;
}

.card{
  width: min(17.58vw, calc(40.89vh*0.63));
  height: min(40.89vh, calc(17.58vw/0.63));
  container-name: card;
  container-type: size;
}

.cardText{
  width: min( 39.06vw, calc(39.62vh*1.6));
  height: min(39.62vh, calc(39.06vw/1.6));
  container-name: cardText;
  container-type: size;
}

.textCorrection{
  width: min(50.20vw, calc(63.39vh*1.29));
  height: min(63.39vh, calc(50.20vw/1.29));
  container-name: textCorrection;
  container-type: size;
}

.piggyBank{
  width: min(calc(29.30vw *1), calc(63.39vh*0.75 * 1));
  height: min(calc(63.39vh*1), calc(29.30vw/0.75 * 1));
  container-name: piggyBank;
  container-type: size;
}

.teamTurn{
  width: min(33.20vw, calc(7.92vh*6.8));
  height: min(7.92vh, calc(33.20vw/6.8));
  container-name: teamTurn;
  container-type: size;  
}

.holderOtherTeams{
  width: min( 31.25vw, calc(19.20vh*2.66));
  height: min(19.20vh, calc(31.25vw/2.66));
  container-name: holderOtherTeams;
  container-type: size;
}

.chat{
  width: min( 24.90vw, calc(72.26vh*0.56));
  height: min(72.51vh, calc(24.90vw/0.56));
  container-name: chat;
  container-type: size;
}

.notebookPositionWaitingRoom{
  bottom: -54cqw;
  left: -19cqw;
}

.fontSizenoteBookWaitingRoom{
  line-height: 2.625cqw;
  font-size: 2.1cqw;
}

.fontSizePostitWaitingRoom{
  font-size: 10.53cqw;
  line-height: 15cqw;
}

.positionChatShown{
  bottom: 2cqw;
  padding-top: 12.55cqw;
  padding-left: 7.84cqw;
  padding-right: 3.53cqw;
  gap: 3.14cqw;
}

.positionChatHidden{
  bottom: -100cqh;
  padding-top: 12.55cqw;
  padding-left: 7.84cqw;
  padding-right: 3.53cqw;
  gap: 3.14cqw;
}

.dimensionChat{
  width: 86.63cqw;
  height: 72.77cqh;
  padding-right: 4.31cqw;
}

.dimmensionInnerChat{
  width: 86.63cqw;
  height: 72.77cqh;
  padding-right: 4.31cqw;
  padding-top: 5.49cqw;
  gap:4.31cqw;
}

.fontSizeMessages{
  font-size: 4.71cqw;
  line-height: 5.49cqw;
  gap: 4.31cqw;
}

.messageInput {
  width: 84.31cqw;
  height: 15.67cqw;
  font-size: 4.71cqw;
  line-height: 5.49cqw;
}

.messageInputTextArea {
  width: 84.31cqw;
  height: 15.67cqw;
  padding: 1.96cqw;
  padding-right: 11.76cqw;

}

.buttonSendMessage{
  width: 7.84cqw;
  height: 6.27cqw;
  bottom: 1.96cqw;
  right: 1.96cqw;
}

.chatCloseComponentPosition{
  top: 20.39cqw;
  right: -17.84cqw;
}

.chatButtonDimension{
  width: 19.41cqw;
  height: 20.20cqw;
}

.positionChatShownAnimator{
  bottom: -11cqw;
  padding-top: 12.55cqw;
  padding-left: 7.84cqw;
  padding-right: 3.53cqw;
  left: -50cqw;
  gap: 3.14cqw;
}

.positionChatHiddenAnimator{
  bottom: -100cqh;
  padding-top: 12.55cqw;
  padding-left: 7.84cqw;
  padding-right: 3.53cqw;
  left: -50cqw;
  gap: 3.14cqw;
}

.fontSizeMoneyAndPleasure{
  font-size: 8.35cqw;
  line-height: 6.66cqw;
}

.positionPostItOne{
  margin-right: 45cqw;
}

.postItProposal{
  padding-top: 27cqw;
  padding-bottom: 23cqw;
  padding-left: 12cqw;
  padding-right: 12cqw;
  font-size: 6.9cqw;
  line-height: 6.9cqw;
}

.fontSizePricePostit{
  font-size: 13cqw;
  line-height: 13cqw;
}

.positionPostItTwo{
  margin-left: 45cqw;
}

.noteBookWaitingRoomShown{
  rotate: -3deg;
  bottom: -3cqw;
  left: -13cqw;
}

.noteBookWaitingRoomHidden{
  rotate: 10deg;
  bottom: -6cqw;
  left: -97cqw;
}

.buttonNoteBookShown{
  top: 6cqw;
  right: 9.5cqw;
  rotate: -3deg;
}

.buttonNoteBookHidden{
  top: 21cqw;
  right: 88.5cqw;
  rotate: 10deg;
}

.postItNoteBookShown{
  rotate: 10deg;
  /* right: -16cqw; */
  /* right: -86cqw;
  top: 14cqw; */
}

.postItNoteBookHidden{
  rotate: -20deg;
  /* right: 95cqw; */
  /* right: 28cqw;
  top: 35cqw; */
}

.postItFontNoteBook{
  font-size: 6.9cqw;
  line-height: 6.9cqw;
  padding-top: 27cqw;
  padding-left: 12cqw;
  padding-right: 12cqw;
}

.postItFontPrice{
  font-size: 13cqw;
  line-height: 13cqw;
  padding-bottom: 23cqw;
  padding-left: 12cqw;
  padding-right: 12cqw;
}

.namePlayerWaitingRoom{
  font-size: 10.53cqw;
  line-height: 15cqw;
}

.fontIncomeNotebook{
  font-size: 1.7cqw;
}

.fontIncomeName{
  font-size: 1.3cqw;
  line-height: 1.8cqw;
}

.fontIncomeValue{
  font-size: 1.5cqw;
  line-height: 1.8cqw;
}

.fontTotalValue{
  font-size: 2.13cqw;
  line-height: 2.53qw;
}

.fontTotalName{
  font-size: 1.6cqw;
  line-height: 2.53qw;
}

.fontFixedCharges{
  font-size: 8.5cqw;
  line-height: 8.5cqw;
}

.paddingChargesNotFixedName{
  padding-left: 3.7cqw;
  padding-top: 3.7cqw;
}

.paddingChargesNotFixedValue{
  padding-left: 3.7cqw;
  /* padding-top: 3.7cqw; */
}

.paddingChargesNotFixedMensuality{
  padding-right: 3.7cqw;
  padding-bottom: 3.7cqw;
}

.gapPleasurePointChargesNotFixed{
  gap: 3.7cqw;
}

.piggyBankOut{
  bottom: -32cqw;
  left: calc(25.8cqw - 14cqw);
  padding-top: 14cqw;
  padding-left: 14cqw;
  background-position-x: 14cqw;
}

.piggyBankHover{
  bottom: -15cqw;
  left: calc(-11cqw - 14cqw);
  padding-top: 14cqw;
  padding-left: 14cqw;
  background-position-x: 14cqw;
}

.piggyBankIn{
  bottom: -15cqw;
  left: calc(-33cqw - 14cqw);
  padding-top: 14cqw;
  padding-left: 14cqw;
  background-position-x: 14cqw;
}

.fontPiggyBank{
  font-size: 6.5cqw;
  line-height: 5.65cqw;
}

.teamInGamePositionHidden{
  top: -27.5cqw;
}

.teamInGamePositionToClick{

}

.dimmentionOtherTeamsAvatars{
  height: 15.625cqw;
  width: 15.625cqw;
}

.dimmensionOtherTeamsCash{
  height: 5cqw;
  width: 7.5cqw;
}

.dimmensionOtherTeamsPleasure{
  height: 5cqw;
  width: 5cqw;
}

.fontTeamTurn{
  font-size: 3.5cqw;
  line-height: 4.1cqw;
}

.fontThrowDice{
  font-size: 6.5cqw;
  line-height: 6.5cqw;

}

.buttonThrowDice{
  width: 50cqw;
}

.fontTitleCard{
  font-size: 5cqw;
  line-height: 5cqw;
}
.fontTextCard{
  font-size: 3cqw;
  line-height: 3.5cqw;
}

.positionCardCorrection{
  top:-22cqw;
  left: -38.5cqw;
}

.fontTitleCardCorrection{
  font-size: 4cqw;
  line-height: 4cqw;
}
.fontTextCardCorrection{
  font-size: 2.42cqw;
  line-height: 2.83cqw;
}

.fontCardNote{
  gap:10cqw;
  font-size: 6.65cqw;
  line-height: 7.8cqw;
}
/* breakpoints for texts */
/* 
@media only screen and (min-width: 1024px) or (min-height: 631px){
  .projectPostitContent {
      font-size: 16px;
      line-height: 16px;
      }
      .projectPostitPrice{
        font-size: calc(30px*1);
        line-height: calc(30px*1);
      }

    .policeChargesPlayer{
        font-size: calc(12px*1);
        line-height: calc(14px*1);
    }
  }

  @media only screen and (min-width: 1280px) or (min-height: 789px){
    .projectPostitContent {
      font-size: calc(16px*1.25);
      line-height: calc(16px*1.25);
      }
      .projectPostitPrice{
        font-size: calc(30px*1.25);
        line-height: calc(30px*1.25);
      }
      .policeChargesPlayer{
        font-size: calc(12px*1.25);
        line-height: calc(14px*1.25);
    }
    }


  
  @media only screen and (min-width: 1536px) or (min-height: 947px)  {
    .projectPostitContent {
      font-size: calc(16px*1.5);
      line-height: calc(16px*1.5);
      }
      .projectPostitPrice{
        font-size: calc(30px*1.5);
        line-height: calc(30px*1.5);
      }
      .policeChargesPlayer{
        font-size: calc(12px*1.5);
        line-height: calc(14px*1.5);
    }
    }




    @media only screen and (min-width: 2048) or (min-height: 1259px){
  .projectPostitContent {
    font-size: calc(16px*2);
    line-height: calc(16px*2);
    }
    .projectPostitPrice{
      font-size: calc(30px*2);
      line-height: calc(30px*2);
    }
    .policeChargesPlayer{
      font-size: calc(12px*2);
      line-height: calc(14px*2);
  }
  } */

/* ------------------------------------------------------------------------------------ */
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}